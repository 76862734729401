import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import PostHeaderIcons from '../post-header-icons';
import { MoreButton } from '../more-button';
import ProfileLink from '../link/profile-link';
import { importPostActions } from '../post-actions';
import AuthorWithPostDetails from '../../../post-page/components/author-with-post-details';
import Avatar from '../avatar';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import styles from './post-list-item-header.scss';

function PostListItemHeader({
  className,
  isMetadataHeaderVisible,
  moreButtonClass,
  moreButtonIcon,
  post,
  showAuthorName,
  showAuthorPicture,
  showAuthorBadge,
  showHeaderIcons,
  showMoreButton,
  showProfileImage,
  showPublishDate,
  showReadingTime,
  style,
  type,
}) {
  const withoutHeaderMetadata = !isMetadataHeaderVisible;
  const containerClassName = classNames(
    styles.container,
    styles[type],
    'blog-post-metadata-font',
    'post-header',
    withoutHeaderMetadata && styles.withoutHeaderMetadata,
    className,
  );
  const moreButtonId = `more-button-${post._id}`;
  const buttonProps = { id: moreButtonId, ...(moreButtonIcon ? { icon: moreButtonIcon } : {}) };

  return (
    <div className={containerClassName} style={style}>
      {isMetadataHeaderVisible && (
        <div className={classNames([styles.metadata, showMoreButton && styles.withThreeDots])}>
          {showAuthorName && showProfileImage && showAuthorPicture && (
            <ProfileLink user={post.owner}>
              <Avatar user={post.owner} isNameVisible={false} />
            </ProfileLink>
          )}
          <AuthorWithPostDetails
            post={post}
            type={type}
            showAuthorName={showAuthorName}
            showAuthorBadge={showAuthorBadge}
            showPublishDate={showPublishDate}
            showReadingTime={showReadingTime}
          />
        </div>
      )}
      {showHeaderIcons && <PostHeaderIcons post={post} className={styles.icons} iconClassName="blog-button-fill" />}
      {showMoreButton && (
        <div className={classNames(styles.moreButton, styles[type], 'blog-more-icon-fill', moreButtonClass)}>
          <MoreButton {...buttonProps}>
            {async () => {
              const PostActions = await importPostActions();
              return <PostActions post={post} focusOnCloseId={moreButtonId} />;
            }}
          </MoreButton>
        </div>
      )}
    </div>
  );
}

PostListItemHeader.propTypes = {
  authorInfoType: PropTypes.number,
  className: PropTypes.string,
  isMetadataHeaderVisible: PropTypes.bool,
  moreButtonClass: PropTypes.string,
  moreButtonIcon: PropTypes.node,
  post: PropTypes.object,
  showAuthorName: PropTypes.bool,
  showAuthorPicture: PropTypes.bool,
  showAuthorBadge: PropTypes.bool,
  showHeaderIcons: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showProfileImage: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string.isRequired,
};

PostListItemHeader.defaultProps = {
  showHeaderIcons: true,
  showMoreButton: true,
  showProfileImage: true,
};

export default withFeedMetadataSettings(PostListItemHeader);
