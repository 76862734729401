import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { connect } from '../runtime-context';
import Counter from '../counter';
import LikeButton from '../like-button';
import styles from './like-button-with-count.scss';
import {
  getIsLiked as getIsPostLiked,
  getIsLikeInProgress as getIsPostLikeInProgress,
  getLikeCount as getPostLikeCount,
  getLikeCountFormatted as getPostLikeCountFormatted,
} from '../../store/post-likes/post-likes-selectors';
import {
  getIsLiked as getIsCommentLiked,
  getIsLikeInProgress as getIsCommentLikeInProgress,
  getLikeCount as getCommentLikeCount,
} from '../../store/comment-likes/comment-likes-selectors';

const LikeButtonWithCount = ({
  className,
  onClick,
  showZero,
  switchCounterAndButton,
  isComment,
  likeCount,
  likeCountFormatted,
  isLiked,
  isLikeInProgress,
}) => {
  return (
    <button className={classNames(styles.button, className)} onClick={onClick}>
      <span
        className={classNames(styles.flexContainer, switchCounterAndButton && styles.switchCounterAndButton)}
        data-hook="like-button-with-count__like-count"
      >
        <Counter
          className={classNames(styles.count, 'like-button-with-count__like-count')}
          count={likeCount}
          countFormatted={likeCountFormatted}
          showZero={showZero}
          ariaHidden={true}
        />
        <LikeButton isLiked={isLiked} likeCount={likeCount} isDisabled={isLikeInProgress} isComment={isComment} />
      </span>
    </button>
  );
};

LikeButtonWithCount.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  showZero: PropTypes.bool,
  switchCounterAndButton: PropTypes.bool,
  isComment: PropTypes.bool,
  likeCount: PropTypes.number.isRequired,
  likeCountFormatted: PropTypes.string,
  isLikeInProgress: PropTypes.bool.isRequired,
  isLiked: PropTypes.bool.isRequired,
};

LikeButtonWithCount.defaultProps = {
  showZero: false,
  switchCounterAndButton: false,
  isComment: false,
};

const mapRuntimeToProps = (state, { isComment, entity }) => {
  return isComment
    ? {
        likeCount: getCommentLikeCount(state, entity._id),
        isLikeInProgress: getIsCommentLikeInProgress(state, entity._id),
        isLiked: getIsCommentLiked(state, entity._id),
      }
    : {
        likeCount: getPostLikeCount(state, entity._id),
        likeCountFormatted: getPostLikeCountFormatted(state, entity._id),
        isLikeInProgress: getIsPostLikeInProgress(state, entity._id),
        isLiked: getIsPostLiked(state, entity._id),
      };
};

export default connect(mapRuntimeToProps)(LikeButtonWithCount);
