import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { GatewayProvider, GatewayDest } from 'react-gateway';

import { getIsPostPageBundle } from '@wix/communities-blog-client-common';

import { isEditor } from '../../store/basic-params/basic-params-selectors';
import AppWrapper from '../app-wrapper';
import DemoMessageRoot from '../../containers/demo-message-root';
import AccessibilityListener from '../accessibility-listener';
import { isInWix } from '../../services/is-in-wix';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import UpdateUrlsNotification from '../../../content-migration/components/update-urls-notification';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { isInPostPreview } from '../../services/detect-route';
import { getRoute } from '../../router/router-selectors';
import styles from './app.scss';

export class AppDesktop extends Component {
  componentDidMount() {
    if (!isInWix() || this.props.isPostPreview) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  render() {
    const {
      children,
      className,
      createdWithResponsiveEditor,
      desktopHeader,
      isEditor,
      isPostPageBundle,
      main,
      menu,
      messageRoot,
      modalRoot,
      popoverRoot,
      showCategoryLabels,
      showBlogMenu,
    } = this.props;

    const showHeader =
      ((createdWithResponsiveEditor && showCategoryLabels) || (!createdWithResponsiveEditor && showBlogMenu)) &&
      desktopHeader;

    return (
      <GatewayProvider>
        <AppWrapper
          className={classNames(className, styles.app, 'blog-background-color', isPostPageBundle && styles.postPageApp)}
        >
          <AccessibilityListener />
          {messageRoot}
          <DemoMessageRoot />
          {modalRoot}
          {popoverRoot}
          <div id="content-wrapper">
            {isEditor && <UpdateUrlsNotification />}
            {showHeader && React.cloneElement(desktopHeader, { menu })}
            {main && React.cloneElement(main, { children })}
          </div>
          <GatewayDest name="tooltip" />
        </AppWrapper>
      </GatewayProvider>
    );
  }
}

AppDesktop.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  createdWithResponsiveEditor: PropTypes.bool,
  desktopHeader: PropTypes.node,
  isEditor: PropTypes.bool,
  isPostPageBundle: PropTypes.bool,
  main: PropTypes.node.isRequired,
  menu: PropTypes.node,
  messageRoot: PropTypes.node.isRequired,
  modalRoot: PropTypes.node.isRequired,
  popoverRoot: PropTypes.node.isRequired,
  showCategoryLabels: PropTypes.bool,
  showBlogMenu: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  isEditor: isEditor(state),
  isPostPageBundle: getIsPostPageBundle(state),
  isPostPreview: isInPostPreview(getRoute(state)),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
});

export default flowRight(connect(mapRuntimeToProps), withBlogMenuSettings)(AppDesktop);
