import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clamp from './clamp';

class DotDotDot extends Component {
  setContainerRef = (element) => (this.containerRef = element);

  componentDidMount() {
    if (this.props.clamp) {
      this.clampText();
      window.addEventListener('resize', this.update, false);
    }
  }

  componentWillUnmount() {
    if (this.props.clamp) {
      window.removeEventListener('resize', this.update, false);
    }
  }

  componentDidUpdate() {
    if (this.props.clamp) {
      this.clampText();
    }
  }

  update = () => this.forceUpdate();

  clampText() {
    if (!this.props.clamp || !this.containerRef) {
      return;
    }

    if (this.containerRef.length) {
      throw new Error('Please provide exacly one child to dotdotdot');
    }

    clamp(this.containerRef, this.props);
  }

  render() {
    const { className, children } = this.props;
    return (
      <div className={className} ref={this.setContainerRef}>
        {children}
      </div>
    );
  }
}

DotDotDot.propTypes = {
  children: PropTypes.node,
  clamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  truncationChar: PropTypes.string,
  useNativeClamp: PropTypes.bool,
  useExactLineHeight: PropTypes.bool,
  className: PropTypes.string,
  maxLineCount: PropTypes.number,
};

DotDotDot.defaultProps = {
  truncationChar: '\u2026',
  useNativeClamp: true,
};

export default DotDotDot;
