import { FEED_WIDGET_NAME } from '../viewer-script/constants/widgets';
import { withViewer } from '../common/hoc/with-viewer';
import AppRoot from './containers/app-root';

const isProduction = process.env.NODE_ENV === 'production';
const stylesConfig = {
  ltrCssPath: isProduction ? 'feed-page-mobile-viewer.min.css' : 'feed-page-mobile-viewer.css',
  rtlCssPath: isProduction ? 'feed-page-mobile-viewer.rtl.min.css' : 'feed-page-mobile-viewer.rtl.css',
};

export default withViewer(AppRoot, stylesConfig, FEED_WIDGET_NAME);
