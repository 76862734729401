import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import PostStats from '../post-stats';
import withFontClassName from '../../hoc/with-font-class-name';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import LikeButtonWithCount from '../like-button-with-count';
import styles from './post-footer.scss';

class PostFooter extends Component {
  handleLikeClick = () => {
    const { onLikeClick, post } = this.props;
    onLikeClick(post._id);
  };

  render() {
    const {
      post,
      displayIcons,
      contentFontClassName,
      commentClassName,
      textColorClassName,
      type,
      forPublicUser,
      showViewCount,
      showCommentCount,
      showLikeCount,
      className,
    } = this.props;

    return (
      <div
        className={classNames(
          styles.container,
          styles[type],
          contentFontClassName,
          textColorClassName,
          'post-footer',
          className,
        )}
      >
        {(showViewCount || showCommentCount) && (
          <div className={styles.leftButtons}>
            <PostStats
              commentClassName={commentClassName}
              post={post}
              displayIcons={displayIcons}
              showViewCount={showViewCount}
              showCommentCount={showCommentCount}
            />
          </div>
        )}
        {showLikeCount && (
          <div className={styles.rightButtons}>
            <LikeButtonWithCount
              onClick={forPublicUser(this.handleLikeClick)}
              entity={post}
              className={'post-footer__like-button'}
            />
          </div>
        )}
      </div>
    );
  }
}

PostFooter.propTypes = {
  post: PropTypes.object.isRequired,
  displayIcons: PropTypes.bool,
  contentFontClassName: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  textColorClassName: PropTypes.string,
  commentClassName: PropTypes.string,
  type: PropTypes.string,
  forPublicUser: PropTypes.func,
  showViewCount: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  className: PropTypes.string,
};

PostFooter.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
  showLikeCount: true,
};

export default flowRight(withFontClassName, withLayoutColorClasses, withAuth, withFeedMetadataSettings)(PostFooter);
